<template>
  <nav class="subnav">
    <div v-for="division in divisions" :key="division">
      <router-link
        :to="{ name: 'Divisions', query: { division: division.id } }"
      >
        {{ division.name }}
      </router-link>
    </div>
  </nav>
</template>

<script>
import json from "@/helpers/divisions.json";
export default {
  name: "SubNav",
  data() {
    return {
      divisions: json.divisions,
    };
  },
};
</script>

<style scoped>
.subnav {
  position: fixed;
  display: flex;
  width: 100%;
  margin: 20px auto;
  border-bottom: 1px solid #363636;
  background-color: #fff;
  z-index: 100;
  padding: 20px;
  left: 0;
}

.subnav a {
  text-decoration: none;
  color: #363636;
  text-transform: uppercase;
  font-size: 18px;
  padding: 1px 15px 1px 0px;
  font-family: azo-sans-web, sans-serif;
}
.subnav a:hover {
  text-decoration: underline;
}

@media all and (max-width: 900px) {
  .subnav{
    display: none;
    visibility: hidden;
  }
}

</style>
