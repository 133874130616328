<template>
  <div class="header">
    <h1>About Us</h1>
  </div>
  <div class="history">
    <h2>THE HISTORY OF GREENSCAPES SIX</h2>
    <p>
      Greenscapes Six was formed with the strategy of offering a unique
      diversified platform of “six” business divisions transferable across the
      landscape, construction, and commercial maintenance industries. Managed by
      Mike Archer and Joey Taylor, the Greenscapes Six executive team has over
      35 years of combined experience in landscape construction, renovation,
      maintenance, and project management. Over the past 10 years alone, they
      have teamed up to complete over $200 million of projects in a variety of
      markets.
    </p>

    <p>
      Prior to forming Greenscapes Six in June 2015, Archer and Taylor were
      responsible for the in-house oversight of all major renovations,
      expansions and golf course capital improvement projects across more than
      50 clubs owned and operated by Sequoia Golf Holdings, the second largest
      owner operator of private clubs in the golf industry. During this time,
      through the vision of Archer, Sequoia established a highly successful
      construction division, offering its in-house expertise and ownership
      mentality for the benefit of third-party clients. Greenscapes Six now
      employs that same expertise and ownership mentality in the execution of
      its many diversified projects today.
    </p>
  </div>
  <div class="team">
    <div class="teamheader">
      <h2>G6 TEAM</h2>
    </div>
    <!--MIKE ARCHER -->
    <div class="teampic">
      <div class="thumbnail" v-for="member in team" :key="member">
        <a class="piclink" @click="toggleModal(member.name, member.bio)">
          <div
            class="pic"
            :style="{ backgroundImage: 'url(' + member.image + ')' }"
          >
            <div class="banner">
              Click to see Biography
            </div>
          </div>
        </a>
        <h3>{{ member.name }}</h3>
        <h4>{{ member.position }}</h4>
      </div>
    </div>
  </div>
  <div class="testimonials_header">
    <h1>TESTIMONIALS</h1>
  </div>
  <div class="slides">
    <carousel :snap-align="center" :wrap-around="true">
      <slide v-for="slide in slides" :key="slide">
        <article>
          <h3>{{ slide.title }}</h3>
          <p>{{ slide.text }}</p>
          <div>
            {{ slide.author }} <span class="i">{{ slide.position }}</span
            ><br />
            {{ slide.location }}
          </div>
        </article>
      </slide>
      <template #addons>
        <navigation>
          <template #next>
            <span class="chevron">
 <svg viewBox="0 0 32 32" aria-hidden="true"><path  color="white" fill="currentcolor" d="M18.629 15.997l-7.083-7.081L13.462 7l8.997 8.997L13.457 25l-1.916-1.916z"/></svg>
            </span>
          </template>
          <template #prev>
            <span class="chevron">
             <svg viewBox="0 0 32 32" aria-hidden="true"><path  color="white" fill="currentcolor" d="M14.19 16.005l7.869 7.868-2.129 2.129-9.996-9.997L19.937 6.002l2.127 2.129z"/></svg>
            </span>
          </template>
        </navigation>
      </template>
    </carousel>
  </div>
  <Modal
    :name="memberName"
    :bio="memberBio"
    v-if="showModal"
    @click="showModal = false"
  />
</template>

<script>
import json from "@/helpers/slides.json";
import team from "@/helpers/team.json";
import Modal from "@/components/Modal";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  name: "About",
  components: {
    Carousel,
    Slide,
    Navigation,
    Modal,
  },
  data() {
    return {
      slides: json.slides,
      team: team.team,
      showModal: false,
      memberName: String,
      memberBio: String,
    };
  },
  methods: {
    toggleModal(name, bio) {
      this.showModal = true;
      this.memberName = name;
      this.memberBio = bio;
      console.log(this.memberName);
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url(https://greenscapessix.com/images/G6_About_Header.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header h1 {
  font-size: 120px;
  font-weight: bold;
  text-align: right;
  color: #fff;
  line-height: 1.25;
  letter-spacing: 2px;
  top: 160px;
  padding-right: 30px;
  font-family: azo-sans-web, sans-serif;
  position: relative;
  text-transform: uppercase;
}
.history {
  position: relative;
  width: 75%;
  margin: auto;
  padding-bottom: 20px;
}

.history h2 {
  font-family: "azo-sans-web";
  text-align: center;
}

.history p {
  font-family: azo-sans-web, sans-serif;
  font-size: 18px;
  color: #363636;
  letter-spacing: 2px;
  padding: 20px;
  line-height: 1.25;
  text-align: justify;
}

.testimonials_header {
  position: relative;
  width: 100%;
  height: 40px;
  margin: auto;
  background-color: #689895;
}

/*team */
.team {
  position: relative;
  background-color: #1f424c;
  width: 100%;
  min-height: 500px;
}

.team h3,
h4 {
  text-align: center;
  color: #fff;
  line-height: 0.5;
}
.teamheader {
  position: relative;
  width: 785px;
  margin: 0 auto;
  border-bottom: 1px solid #fff;
  top: 30px;
}

.teamheader h2 {
  font-family: azo-sans-web, sans-serif;
  text-align: center;
  color: #fff;
}

.team .teampic {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 500px;
  margin: 30px auto;
  padding-top: 30px;
}

.team .teampic .thumbnail {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 229px;
  height: 229px;
  padding-left: 0px;
}
.team a {
  text-decoration: none;
  color: #fff;
}

.team a:hover {
  cursor: pointer;
}

.team .teampic .thumbnail a .pic {
  height: 100%;
}

.team .teampic .thumbnail a .piclink {
  width: 100%;
  height: auto;
  overflow: hidden;
  background-repeat: no-repeat;
}

.team .teampic .thumbnail a .pic .banner {
  width: 100%;
  background-color: #9dbb68;
  font-size: 16px;
  color: #fff;
  text-align: center;
  padding: 7px 0;
  top: 200px;
  position: relative;
}
/*Testimonials */


.testimonials_header h1 {
  text-align: center;
  font-family: azo-sans-web, sans-serif;
  color: #fff;
  margin: 0;
}

.slides {
  position: relative;
  width: 100%;
  min-height: 613px;
  background-image: url(https://greenscapessix.com/images/testimony_splash.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slides .carousel .carousel__slide {
  left: 20px;
}
.slides .carousel {
  position: relative;
  top: 40px;
  margin: 0 auto;
  width: 60%;
  height: 500px;
  background-color: #689895;
  padding: 20px;
}

.slides .carousel .chevron svg{
  height: 50px;
}

.slides .carousel article {
  width: 80%;
}

.slides .carousel .carousel__slide h3 {
  font-family: azo-sans-web, sans-serif;
  font-weight: lighter;
  text-align: center;
  color: #fff;
  font-size: 24px;
  letter-spacing: 2px;
}

.slides .carousel .carousel__slide p {
  font-family: azo-sans-web, sans-serif;
  text-align: justify;
  color: #fff;
  font-size: 18px;
  letter-spacing: 2px;
}

.slides .carousel .carousel__slide div {
  font-family: azo-sans-web, sans-serif;
  text-align: left;
  color: #fff;
  font-size: 16px;
  letter-spacing: 2px;
}


@media all and (max-width: 1200px) {
  .header h1 {
    font-size: 75px;
    top: 200px;
  }
  .slides .carousel .carousel__slide p {
    font-size: 14px;
  }
}

@media all and (max-width: 785px) {
  .teamheader {
    width: 100%;
  }

  .slides,
  .testimonials_header {
    display: none;
    visibility: hidden;
  }

  .history p {
    text-align: center;
  }
}

@media all and (max-width: 550px) {
  .team .teampic {
    width: auto;
    flex-flow: row wrap;
    padding-top: 0;
    padding-bottom: 30px;
    margin: 0;
  }

  .team .teampic .thumbnail {
    margin: 50px auto;
  }

  .header h1 {
    font-size: 32px;
    top: 240px;
  }
}

@media all and (max-width: 450px) {
  .history {
    width: 100%;
  }
}
</style>
