<template>
  <Header/>
  <router-view :key="$route.fullPath" />
  <Footer/>
</template>

<script>
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';


export default {
  name: 'App',
  components: {
    Footer,
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
#app .slides section .carousel__prev, #app .slides section .carousel__next{
  background-color: transparent !important;
}


@media all and (max-width: 900px) {

#app{
  margin-top: -20px;
}
}
</style>
