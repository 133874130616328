<template>
    <div class="header">
        <h1>Clients</h1>
    </div>
   <div class="list">
        <ul>
            <li v-for='client in clientlist' :key='client'>
                {{client}}
            </li>
        </ul>
    </div>
</template>

<script>
import json from "@/helpers/clients.json"
export default {
  name: 'Clients',
  data() {
      return {
        clientlist: json.clientlist
      }
  }
}
</script>

<style scoped>
.header {
    position: relative;
    width: 100%;
    height: 300px;
    background-image: url(https://greenscapessix.com/images/G6_CaseStudies_Header.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.header h1{
    font-size: 120px;
    font-weight: bold;
    text-align: right;
    color: #fff;
    line-height: 1.25;
    letter-spacing: 2px;
    top: 160px;
    padding-right: 30px;
    font-family: azo-sans-web, sans-serif;
    text-transform: uppercase;
    position: relative;
}

.list{
    margin: 100px auto;
}

.list ul{
    list-style: none;
    text-align: left;
    column-count: 3;
    margin: 0 auto;
}

.list ul li{
  padding-bottom: 15px;
  font-size: 24px;  
}
@media all and (max-width: 1200px) {
.header h1{
font-size: 75px;
top: 200px;
}
}

@media all and (max-width: 785px) {
.list ul{
    column-count: 2;
}

.list ul li{
  font-size: 18px;  
}
}

@media all and (max-width: 500px) {
.list ul{
    column-count: 1;
}

.list ul li{
  font-size: 14px;  
}
}
</style>