<template>
  <div class="header">
    <h1>G6 Flywheel</h1>
  </div>
    <nav class="subnav">
  <div  v-for="division in divisions" :key="division">
    <router-link :to="{name: 'Divisions', query: {division: division.id}}">
        {{division.name}}
    </router-link>
    </div>
  </nav>
  <div class="flywheel_container">
    <div class="flywheel">
    <router-link v-for="division in divisions" 
    class="division" :style="division.coords" 
    :to="{name: 'Divisions', query: {division: division.id}}" :key="division">
    </router-link>
    </div>
    <div class="text">
      <p>
        A “Flywheel” is a heavy revolving wheel in a machine that is used to
        produce momentum. The G6 Flywheel represents the momentum generated by
        the successful transfer of construction, renovation and project
        management expertise into a variety of industries that all benefit from
        the experience of the Greenscapes Six team.
      </p>
      <p>
        With the completion of over 400 projects the G6 process has proven to
        deliver efficient solutions to both simple and complex projects
        utilizing competent personnel, proven strategies, and timely execution,
        all of which contribute to a quality finished project performed on
        schedule and within budget.
      </p>
      <span>Click on any Flywheel area to learn more</span>
    </div>
  </div>
</template>

<script>
import json from "@/helpers/divisions.json";
export default {
  name: "Services",
  data() {
    return {
      divisions: json.divisions,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.header {
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url(https://greenscapessix.com/images/G6_Flywheel_Header.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header h1 {
  font-size: 120px;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.25;
  letter-spacing: 2px;
  top: 160px;
  padding-right: 30px;
  font-family: azo-sans-web, sans-serif;
  position: relative;
  text-decoration: uppercase;
}
.history {
  position: relative;
  width: 75%;
  margin: auto;
  padding-bottom: 20px;
}

.history h2 {
  font-family: "azo-sans-web";
  text-align: center;
}

.history p {
  font-family: azo-sans-web, sans-serif;
  font-size: 18px;
  color: #363636;
  letter-spacing: 2px;
  padding: 20px;
  line-height: 1.25;
  text-align: justify;
}

.flywheel_container {
  background-image: url(https://greenscapessix.com/images/stacked_circles2X.png);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 50px;
}

.flywheel_container .flywheel {
  display: block;
  background-image: url(https://greenscapessix.com/images/flywheel.png);
  background-repeat: no-repeat;
  position: relative;
  padding: 0px;
  width: 583px;
  height: 641px;
}

.flywheel_container .flywheel .division{
 position: absolute;
    display: block;
    width: 138px;
    height: 138px;
    background: #9dbb68;
    opacity: 0;
    border-radius: 50%;
    transition: opacity .5s;
}

.flywheel_container .flywheel .division:hover{
    opacity: 0.5;
}

.flywheel_container .text {
  position: relative;
  width: 50%;;
}

.flywheel_container .text p {
  position: relative;
  padding: 0 20px;
  font-size: 25px;
  color: #363636;
  text-align: left;
  margin: 5px 0 20px;
}
.flywheel_container .text span {
  text-align: left;
  display: block;
  padding-left: 20px;
  font-style: italic;
  font-weight: bold;
}
.subnav {
    display: flex;
    margin: 0 auto;
    border-bottom: 1px solid #363636;
    background-color: #fff;
    z-index: 100;
    padding: 20px;
    left: 0;
}

.subnav a{
    text-decoration: none;
    color: #363636;
    text-transform: uppercase;
    font-size: 18px;
    padding: 1px 15px 1px 0px;
    font-family: azo-sans-web, sans-serif;
}
.subnav a:hover{
    text-decoration: underline;
} 

@media all and (max-width: 1400px) {
.flywheel_container .text {
  width: 45%;
}
}

@media all and (max-width: 1200px) {
.flywheel_container .text p{
  font-size: 21px;
}
.header h1{
font-size: 75px;
top: 200px;
}
}
@media all and (max-width: 1100px) {
.flywheel_container{
 flex-flow: row wrap;
}

.flywheel_container .flywheel{
    position: relative;
    left: 15%;
    padding-bottom: 30px;
}
.flywheel_container .text {
  width: 95%;
}
}

@media all and (max-width:785px){
  
.flywheel_container .flywheel{
 left: 3%;
}

.subnav a{
  font-size: 12px;
}
}

@media all and (max-width:700px){
  .header h1{
    font-size: 32px;
    top: 240px;
}
  
.flywheel_container .flywheel, .flywheel_container .text span{
 display: none;
}
 
}

@media all and (max-width: 450px) {
  .subnav{
    display: block;
  }
}
</style>
