<template>
  <header>
    <div id="header_content">
      <div id="logo">
        <a href="/"><img src="../assets/images/logo.png" alt="logo"/></a>
      </div>
      <nav>
        <ul>
          <li><router-link to="/">HOME</router-link></li>
          <li><router-link to="/About">ABOUT</router-link></li>
          <li><router-link to="/Services">SERVICES</router-link></li>
          <li><router-link to="/Clients">CLIENTS</router-link></li>
          <li><router-link to="/Contact">CONTACT</router-link></li>
        </ul>
      </nav>
      <div @click="toggleMobileMenu" class="mobile_button"></div>
      <div class="mobile_menu" :style ="{display: showMobileMenu, maxHeight: menuHeight}">
        <ul>
          <li><router-link to="/">HOME</router-link></li>
          <li><router-link to="/About">ABOUT</router-link></li>
          <li><router-link to="/Services">SERVICES</router-link></li>
          <li><router-link to="/Clients">CLIENTS</router-link></li>
          <li><router-link to="/Contact">CONTACT</router-link></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      subnav: false,
      showMobileMenu: 'none',
      menuHeight: '0'
    };
  },
  methods: {
      toggleMobileMenu() {
          if(this.showMobileMenu === 'none'){
              this.showMobileMenu = 'block';
              this.menuHeight = '200px'
          } else {
              this.showMobileMenu = 'none';
              this.menuHeight = '0'
          }
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  background-attachment: fixed;
  font-family: azo-sans-web, sans-serif;
  font-size: 100%;
}
body img {
  max-width: 100%;
  height: auto;
}

/*HEADER*/

header {
  width: 100%;
  height: 75px;
  background-color: #fff;
  margin: 0;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  border-top: 10px solid #9dbb68;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}
nav {
  position: relative;
  color: #363636;
  float: right;
  top: -40px;
  right: 20px;
}
nav ul {
  letter-spacing: 2px;
  font-size: 18px;
}
nav ul li {
  display: inline-block;
  margin: 0;
  list-style: none;
  border-right: 1px solid #363636;
}

nav ul li:last-child {
  border-right: 1px solid transparent;
}
.rborder {
  border-right: 2px #ffffff solid;
}
nav ul li a {
  color: #363636;
  text-decoration: none;
  padding: 1px 15px;
  font-family: "azo-sans-web";
}
nav ul li a:hover,
.splash_button a:hover {
  color: #689895;
  text-decoration: underline;
  transition: all 0.5s ease;
}
area {
  display: block;
}
#header_content {
  width: 98%;
  height: 75px;
  margin: 0 auto;
  color: #000;
}

#logo {
  position: relative;
  top: 10px;
  width: 350px;
  height: 55px;
  background-repeat: no-repeat;
}

.mobile_menu{
    display: block;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.75);
    width: 100%;
    right: 0;
    top: 70px;
    height: auto;
    max-height: 0;
    transition: all .5s ease;
}

.mobile_menu ul{
    list-style: none;
    text-align: left;
    font-size: 18px;
    font-family: azo-sans-web, sans-serif;
    color: #363636;
}

.mobile_menu ul li{
 padding-bottom: 7px;
}

.mobile_menu ul li a{
    text-decoration: none;
    color: #363636;
}
.mobile_button {
  display: none;
  visibility: hidden;
  height: 32px;
  width: 32px;
  top: 20px;
  position: absolute;
  background-image: url(https://greenscapessix.com/images/menu.png);
}

.mobile_button:hover{
opacity: 0.5;
}
@media all and (max-width: 950px) {
  nav {
    top: -36px;
  }
  nav ul {
    font-size: 14px;
  }

  #logo {
    top: 20px;
    width: 250px;
  }
}

@media all and (max-width: 780px) {
  nav {
    display: none;
    visibility: hidden;
  }

  #logo {
    float: right;
    padding-right: 30px;
  }
  .mobile_button {
    display: block;
    visibility: visible;
  }
}
</style>
