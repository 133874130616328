<template>
    <div class="header">
        <h1>Greenscapes Six</h1>
    </div>
    <div class="top-section">
        <p>Greenscapes Six is a diversified platform of solution-based resources transferrable across
the construction, landscaping, and maintenance industries. The company is comprised
of six operating divisions providing a variety of construction, renovation and
maintenance services for municipalities, golf courses, school districts, athletic clubs, and
commercial clients.</p>
        <p>The Greenscapes executive team has over 35 years of combined experience in
managing construction, renovation, and maintenance projects. Greenscapes Six
provides clients with efficient solutions to both simple and complex projects utilizing
competent personnel and proven strategies for a quality product delivered on
schedule and within budget.</p>
    </div>
<div class="middle-section">
     <div class="divisions" v-for='division in divisions' :key='division'>
       <div class="pic">
           <router-link :to="{name: 'Divisions', query: {division: division.id}}">
               <img :src="division.home_image" :alt="division.name" />
           </router-link>
           <h2>{{division.name}}</h2>
           <p>{{division.home_copy}}</p>
       </div>
    </div>  
</div>
<div class="bottom-section">
    <p>Greenscapes Six provides clients with efficient solutions to both simple and complex projects utilizing competent personnel, proven strategies, and timely execution within budget.</p>
</div>
</template>

<script>
import json from "@/helpers/divisions.json"
export default {
  
  name: 'Clients',
  data() {
      return {
        divisions: json.divisions
      }
  }
}
</script>

<style scoped>
.header {
    position: relative;
    width: 100%;
    height: 300px;
    background-image: url(https://greenscapessix.com/images/Home_Image.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.header h1{
  font-size: 120px;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.25;
  letter-spacing: 2px;
  top: 160px;
  padding-right: 30px;
  font-family: azo-sans-web, sans-serif;
  position: relative;
  text-decoration: uppercase;
}

.list{
    margin: 50px;
}

.list ul{
    list-style: none;
    text-align: left;
}

.list ul li{
  padding-bottom: 15px;
  font-size: 18px;  
}

.bottom-section{
    position: relative;
    width: 100%;
    top: 0px;
    background-color: #9dbb68;
    padding: 25px 0px;  
}

.top-section{
    position: relative;
    width: 75%;
    margin: auto;
    padding-bottom: 20px;
}

.top-section p{
    font-family: azo-sans-web, sans-serif;
    font-size: 18px;
    color: #363636;
    letter-spacing: 2px;
    padding: 20px;
    line-height: 1.25;
    text-align: justify;
}

.middle-section{
    position: relative;
    margin: 50px auto;
    width: 1000px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.middle-section .pic{
    position: relative;
    top: 0;
    width: 300px;
    max-height: auto;
    margin-bottom: 10px;
}

.middle-section .pic a img{
    position: relative;
    top: 0;
    width: 100%;
}

.middle-section .pic a img:hover{
   opacity: .50;
}

.middle-section h2{
    font-family: azo-sans-web, sans-serif;
    text-align: center;
    font-weight: lighter;
    line-height: .5;
    color: #363636;
}

.middle-section p {
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 2px;
}
.bottom-section p{
    position: relative;
    width: 80%;
    margin: auto;
    font-weight: lighter;
    font-size: 14px;
    color: #fff;
    letter-spacing: 2px;
    line-height: 1.25;
    text-align: justify;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: azo-sans-web, sans-serif;
}

@media all and (max-width: 1200px) {

.header h1{
font-size: 75px;
top: 200px;
}
}


@media all and (max-width: 1000px) {

.middle-section{
    width: 80%;
}
}
@media all and (max-width: 780px) {

.middle-section{
    width: 57%;
}

.middle-section .pic{
    width: 400px;
}
.top-section p{
    text-align: center;
}
}

@media all and (max-width: 700px) {
.header h1{
    font-size: 50px;
    top: 240px;
}
}
@media all and (max-width: 550px) {
.header h1{
    font-size: 32px;
    top: 240px;
}

.middle-section{
   margin: 0 auto;
   width:95%;
}

}

</style>