<template>
  <div class="mask">
    <div class="modal-wrapper">
      <div class="modal">
        <h2>{{ name }}</h2>
        <h3>Managing Partner</h3>
        <p v-html="bio"></p>
        <button>X</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["name", "bio"],
};
</script>

<style scoped>
.mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.mask .modal-wrapper{
  display: table-cell;
  vertical-align: middle;
}
.mask .modal {
  width: 80%;
  margin: auto;
  background-color: #fff;
  padding: 30px;
  border: 2px solid #959595;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
  border-radius: 6px;
}

.mask .modal p, h3{
    text-align: left;
}

.mask .modal button {
    position: relative;
    float: right;
}
</style>
