<template>
<footer>
<div class="contact">
        <span>(713) 714-3500 <br> © &nbsp; {{ year() }} GREENSCAPES SIX </span>
</div>
<div class="social">        
        <a href="https://www.facebook.com/pages/Greenscapes-Six/1517729925143232?fref=ts" target="_blank"><img src="../assets/images/facebook.png"></a> &nbsp;
        <a href="https://www.linkedin.com/company/10083823?trk=tyah&amp;trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A10083823%2Cidx%3A1-1-1%2CtarId%3A1438784432304%2Ctas%3AGreenscapes%20Six" target="_blank"><img src="../assets/images/linkedin.png"></a>       
        <a href="https://www.instagram.com/greenscapessix/" target="_blank"><img src="../assets/images/Instagram-icon.png"></a>
    </div>
</footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    year(){ 
      return new Date().getFullYear()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    justify-content:flex-end;
    width: 100%;
    height: 100px;
    background-color: #363636;
    color: #fff;
}
footer .contact {
    position: relative;
    color: #fff;
    text-align: right;
    margin-top: 25px;
    line-height: 1.25;
    font-size: 20px;
}

footer .social{
    margin: 30px;
}

@media all and (max-width: 450px) {
 footer .contact {
   font-size: 14px;
 }
}
</style>