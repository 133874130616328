<template>
      <SubNav/>
    <div class="content">
    <div class="top">
      <div class="title">
        <h1>{{ divisionData.name }}</h1>
      </div>
      <div class="top_frame">
        <p v-html="divisionData.landing_copy"></p>
        <img :src="divisionData.top_image" :alt="divisionData.name" />
      </div>
    </div>
    <div class="bottom">
      <img :src="divisionData.bottom_image" :alt="divisionData.name" />
      <div class="services">
        <h1>Services Include:</h1>
        <ul>
          <li v-for="item in divisionData.services" :key="item">
            <span v-html="item"> </span>
          </li>
        </ul>
      </div>
    </div>
    </div>
</template>

<script>
import json from '@/helpers/divisions.json';
import SubNav from '@/components/SubNav.vue';
export default {
  name: 'Divisions',
  components: {
      SubNav
  },
  data() {
    return {
      divisions: json.divisions,
    };
  },
  created() {
    this.division_name = this.$route.query.division;
  },
  computed: {
    divisionData() {
      const data = this.divisions.find(({ id }) => id === this.division_name);
      return data;
    },
  },
};
</script>

<style scoped>
.content{
    position: relative;
    top: 75px;
}
.top {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
  background-color: #1f424c;
}
.top .title {
  position: relative;
  width: 90%;
  margin: 30px auto;
  border-bottom: 1px solid #fff;
  top: 30px;
}

.top .title h1 {
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
  font-family: azo-sans-web, sans-serif;
  
}

.top .top_frame {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  padding: 20px 0;
}

.top .top_frame p {
  font-size: 18px;
  color: #fff;
  text-align: left;
  display: block;
  width: 45%;
  margin-top: 0;
}

.top .top_frame img {
  width: 45%;
  height: auto;
}

.bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 50px;
}

.bottom img {
  width: 45%;
  height: auto;
}

.bottom .services{
    width: 45%;
    padding: 50px;
}

.bottom ul li{
    margin-bottom: 15px;
    font-size: 18px;
    color: #363636;
    text-align: left;
    font-family: azo-sans-web, sans-serif;
}

.bottom h1 {
  text-align: center;
  font-size: 24px;
  color: #363636;
  font-family: azo-sans-web, sans-serif;
}

@media all and (max-width: 900px) {

.top .top_frame{
  flex-direction: column-reverse;
}

.top .top_frame img{
  width: 100%;
  max-width: auto;
  max-height: auto;
  margin-bottom: 20px;
}
}

@media all and (max-width: 750px) {

  .bottom img{
    display: none;
    visibility: hidden;
  }

  .bottom .services{
    width: 95%;
  }
  .top .top_frame p {
    display: block;
    width: 95%;
  }
}
</style>
