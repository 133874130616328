<template>
<div class="contactbg">
  <div class="header">
    <h1>Contact <span class="lighter"></span>Us</h1>
  </div>
  
  <div class="contactbox">


    <div class="info">
            <div class="contacts">
                <div class="partner">
                    <h2>MIKE ARCHER</h2>
                    <h3>Managing Partner</h3>
                    <a href="mailto:marcher@greenscapes6.com">marcher@greenscapes6.com</a><br>
                    <a href="tel: +17137143500">713-714-3500</a>
                </div>
                <div class="partner">
                    <h2>JOEY TAYLOR</h2>
                    <h3>Managing Partner</h3>
                    <a class="email" href="mailto:jtaylor@greenscapes6.com">jtaylor@greenscapes6.com</a><br>
                    <a clas="phone-number" href="tel: +17137141200">713-714-1200</a>
                </div>
            </div>
            </div>
        </div>
</div>    
</template>

<script>
export default {
  name: 'Contact',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.contactbg{
    position: relative;
    top: 10px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-image: url(https://greenscapessix.com/images/G6_Contact_Page_Img.jpg);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.contactbg .header{
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding-right: 30px;
}

.contactbg .header h1{
  font-size: 120px;
  font-weight: bold;
  text-align: right;
  color: #fff;
  line-height: 1.25;
  letter-spacing: 2px;
  top: 160px;
  padding-right: 30px;
  font-family: azo-sans-web, sans-serif;
  position: relative;
  text-transform: uppercase;
 }

 .contactbg .header h1 span.lighter{
    font-size: 120px;
    font-weight: lighter;
    text-align: right;
    color: #fff;
    line-height: 1.25;
    letter-spacing: 2px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
 }

.contactbox {
    position: relative;
    top: 25%;
    margin: 50px auto;
    padding-bottom: 100px;
    width: 95%;
}

.contactbox .info a {
    color: #363636;
    text-decoration: none;
    font-size: 21px;
    letter-spacing: 2px;
    text-align: center;
}
.contactbox a:hover {
    color: #363636;
    text-decoration: underline;
}
.contactbox h3,
.contactbox h2,
.contactbox h1,
.contactbox h4,
.contactbox a {
    text-align: center;
    color: #363636;
    margin: 0;
    line-height: 2;
}


.contactbox h3 {
    font-size: 24px;
    font-weight: lighter;
    line-height: 1;
}

.info .contacts {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 55%;
    margin: auto;
    height: 200px;
}

.info .contacts .partner {
    position: relative;
    text-align: center;
    color: #363636;
}

@media all and (max-width: 1200px) {
.contactbg .header h1{
font-size: 75px;
top: 200px;
}
.contactbox {
  top: 15%;
}

.info .contacts {
  width: 70%;
}
}

@media all and (max-width: 900px) {
.contactbg .header h1{
    padding-right: 18%;
    top: 160px;
}
.info .contacts {
  width: 40%;
  flex-flow: row wrap;
} 
.info .contacts .partner{
  padding-bottom: 30px;
} 
}


@media all and (max-width: 700px) {
.contactbg .header h1{
    font-size: 50px;
    padding-right: 8%;
}
}
@media all and (max-width: 600px) {
.info .contacts{
    width: 60%;
}
}

@media all and (max-width: 550px) {
.contactbg .header h1{
    font-size: 32px;
    top: 130px;
}
}
</style>